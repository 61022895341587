<template>
  <div class="card-header border-bottom pt-1 pb-1 header_txt">
    <h4 class="card-title">Profile</h4>
  </div>
  <div class="card-body mt-2">
    <vForm @submit="$emit('storeContact', formData)" :validation-schema="schema">
      <div class="row">
        <div class="col-md-6 offset-md-3 col-12">
          <div class="row">

            <div class="col-12">
              <div class="mb-1 row">
                <vField v-model="formData.full_name" type="text" name="full_name"
                        class="form-control add-credit-card-mask" placeholder="Full Name"/>
                <ErrorMessage name="full_name" class="invalid-feedback d-block qcont"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="legal-name" class="form-control" v-model="formData.mobile_no"
                       placeholder="Mobile No"/>
              </div>
            </div>

            <div class="col-6">
              <div class="mb-1 row">
                <input @change="getBirthDayCal" type="date" id="birthday" class="form-control"
                       v-model="formData.birthday"
                       placeholder="Birthday"/>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-1 row">
                <select class="form-control" id="gender" v-model="formData.gender">
                  <option value=''>Select Gender</option>
                  <option v-for="(gender, key) in genders" :value="key">{{ gender }}</option>
                </select>
              </div>
            </div>

            <div class="col-4">
              <div class="mb-1 row">
                <input @input="getAgeCal" type="text" id="age_year" class="form-control" v-model="formData.age_year"
                       placeholder="Year"/>
              </div>
            </div>
            <div class="col-4">
              <div class="mb-1 row">
                <input max="12" @input="getAgeCal" type="text" id="age_month" class="form-control"
                       v-model="formData.age_month" placeholder="Month"/>
              </div>
            </div>
            <div class="col-4">
              <div class="mb-1 row">
                <input max="31" @input="getAgeCal" type="text" id="age_day" class="form-control"
                       v-model="formData.age_day" placeholder="Day"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <select class="form-control" id="gender" v-model="formData.blood_group">
                  <option value=''>Select blood group</option>
                  <option v-for="(blood_group, key) in blood_groups" :value="key">{{ blood_group }}</option>
                </select>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <select class="form-control" id="referred_by" v-model="formData.referred_by">
                  <option value=''>Select Referred By</option>
                  <option v-for="(doctor, key) in doctors" :value="doctor.full_name">{{ doctor.full_name }}</option>
                </select>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="nid" class="form-control" v-model="formData.nid"
                       placeholder="NID / Passport No"/>
              </div>
            </div>
            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="father-name" class="form-control" v-model="formData.father_name"
                       placeholder="Father Name"/>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="mother-name" class="form-control" v-model="formData.mother_name"
                       placeholder="Mother Name"/>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-1 row">
                <select class="form-control" id="marital_status" v-model="formData.marital_status">
                  <option value=''>Select Marital status</option>
                  <option v-for="(marital, key) in marital_status" :value="key">{{ marital }}</option>
                </select>
              </div>
            </div>
            <div class="col-6">
              <div class="mb-1 row">
                <select class="form-control" id="religion" v-model="formData.religion">
                  <option value=''>Select Religion</option>
                  <option v-for="(religion, key) in religions" :value="key">{{ religion }}</option>
                </select>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-1 row">
                <input type="text" id="location" class="form-control" v-model="formData.occupation"
                       placeholder="Occupation"/>
              </div>
            </div>

            <div class="col-sm-6 offset-sm-3">
              <button type="submit" class="btn btn-primary me-1">Submit</button>
              <button type="reset" class="btn btn-outline-secondary">Reset</button>
            </div>

          </div>
        </div>
      </div>
    </vForm>
  </div>
</template>

<script setup>
import handleChartAccount from '@/services/modules/chartAccount'
import {onMounted, ref, watch, defineProps} from "vue";
import handleHospital from "@/services/modules/hospital";

const {dateOfBarthConvertToAge} = handleHospital()
let genders = ref([])
let blood_groups = ref([])
let marital_status = ref([])
let religions = ref([])
let loader = ref(false)
const {fetchHome} = handleChartAccount()
const props = defineProps({
  companyRules: {
    type: Array,
    default: []
  },
  doctors: {
    type: Array,
    default: []
  },
  formData: {
    type: Object
  }
})

const schema = {
  full_name: 'required'
}

const formData = ref({
  full_name: '',
  mobile_no: '',
  birthday: '',
  gender: '',
  age_year: '',
  age_month: '',
  age_day: '',
  blood_group: '',
  referred_by: '',
  nid: '',
  father_name: '',
  mother_name: '',
  marital_status: '',
  religion: '',
  occupation: '',
  company_rules: []
})

const getBirthDayCal = () => {
  const {year, month, day} = dateOfBarthConvertToAge(formData.value.birthday)
  formData.value.age_year = year
  formData.value.age_month = month
  formData.value.age_day = day
}

const validateAgeMonth = () => {
  const maxMonth = 12;
  if (formData.value.age_month > maxMonth) {
    formData.value.age_month = maxMonth.toString();
  }
};

const validateAgeDay = () => {
  const maxDay = 31;
  if (formData.value.age_day > maxDay) {
    formData.value.age_day = maxDay.toString();
  }
};

const getAgeCal = () => {
  validateAgeMonth();
  validateAgeDay();

  const currentDate = new Date();

  const years = formData.value.age_year || 0;
  const months = formData.value.age_month || 0;
  const days = formData.value.age_day || 0;

  const calculatedDate = new Date(
      currentDate.getFullYear() - years,
      currentDate.getMonth() - months,
      currentDate.getDate() - days
  );
  formData.value.birthday = calculatedDate.toISOString().split('T')[0];
};

watch(() => [props.formData, props.companyRules], ([newFormData, newFormData2]) => {
  formDataSet();
});

const formDataSet = () => {
  if (props.formData?.full_name) {
    formData.value = props.formData
    let keys = []
    Object.keys(props.formData.company_roles_data).forEach(key => {
      keys.push(parseInt(key))
    });
    formData.value.company_rules = keys
    return getBirthDayCal()
  }

  if (props.companyRules.length === 1) formData.value.company_rules = [props.companyRules[0].id]
}

onMounted(() => {
  formDataSet()
  Promise.all([
    fetchHome().then(res => {
      if (res.data) {
        genders.value = res.data.genders
        blood_groups.value = res.data.blood_groups
        marital_status.value = res.data.marital_status
        religions.value = res.data.religions
      }
    })
  ]).then(() => {
    loader.value = false
  }).catch((err) => {
    loader.value = false
  })
})

</script>

<style scoped>

</style>
